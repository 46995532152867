@font-face {
  font-family: "Font Awesome Regular";
  src: url("./fonts/fontawsome/fa-regular-400.eot");
  src: url("./fonts/fontawsome/fa-regular-400.woff") format("woff"),
  url("./fonts/fontawsome/fa-regular-400.svg#filename") format("svg");
}
@font-face {
  font-family: "Font Awesome Solid";
  src: url("./fonts/fontawsome/fa-solid-900.eot");
  src: url("./fonts/fontawsome/fa-solid-900.woff") format("woff"),
  url("./fonts/fontawsome/fa-solid-900.svg#filename") format("svg");
}

body {
  overflow: hidden;
  background-color: #cfcfcf !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*Hide the iframe displayed by something and blocked the GUI in dev mode*/
body > iframe {
  display: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body, #app, #app>div {
  height: 100%
}
.MuiContainer-maxWidthLg {
  max-width: none !important;
}

@keyframes blink-fade {
  from {
    background: #e7aa70;
  }
  to {
    background: #faede1;
  }
}
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 200px 0;
  }
}
@keyframes heartbeat
{
  0%
  {
    transform: scale( 1 );
  }
  10%
  {
    transform: scale( 1.25 );
  }
  20%
  {
    transform: scale( 1 );
  }
  30%
  {
    transform: scale( 1.25 );
  }
  40%
  {
    transform: scale( 1 );
  }
  100%
  {
    transform: scale( 1 );
  }
}
@keyframes circle
{
  0%
  {
    transform: rotate(0);
  }
  100%
  {
    transform: rotate(360deg);
  }
}

@keyframes circle
{
  0%
  {
    transform: rotate(0);
  }
  100%
  {
    transform: rotate(360deg);
  }
}

@keyframes fillAnimation {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

/**********************************************************/
/*                      Legacy (fix Data Grid menu)       */
/**********************************************************/
.MuiMenuItem-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  justify-content: start !important;
}
.MuiList-padding {
  display: grid !important;
}
.MuiDataGrid-menuList .MuiDivider-root {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}


/**********************************************************/
/*                      Scrollbar                         */
/**********************************************************/
::-webkit-scrollbar { width: 10px; height: 10px; }
::-webkit-scrollbar-button {  background-color: hsl(348, 0%, 40%); width: 0px; height: 0px;}
::-webkit-scrollbar-track {  background-color: transparent;}
::-webkit-scrollbar-track-piece { background-color: transparent;}
::-webkit-scrollbar-thumb { height: 50px; background-color: hsla(348, 0%, 40%, 0.49); border-radius: 4px;opacity:0;transition: 0.3s;}
::-webkit-scrollbar-thumb:hover { background-color: hsla(348, 0%, 40%, 0.60); }
::-webkit-scrollbar-corner { background-color: transparent;}
::-webkit-resizer { background-color: hsl(348, 0%, 40%);}


/**********************************************************/
/*                      Splitter                          */
/**********************************************************/
.splitter-layout > .layout-splitter {
  background-color: transparent !important;
  /*margin: 2px;*/
}
.splitter-layout .layout-splitter:hover {
  background-color: transparent !important;
}


.splitter-layout > .layout-splitter::after,
.splitter-layout > .layout-splitter::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  /*background-color: #165c90;*/
  background-color: #9f9f9f;
  margin-left: 2px;
  width: 5px;
  height: 5px;
  border-radius: 17px;
  opacity: 0.5;
  transition: 0.3s;
}

.splitter-layout-vertical > .layout-splitter::after,
.splitter-layout-vertical > .layout-splitter::before {
  content: "";
  position: absolute;
  top: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  /*background-color: #165c90;*/
  background-color: #9f9f9f;
  margin-top: 2px;
  width: 5px;
  height: 5px;
  border-radius: 17px;
  opacity: 0.5;
  transition: 0.3s;
}
.layout-splitter:hover::after,
.layout-splitter:hover::before {
  /*background-color: #e19542;*/
  background-color: #131313;
  opacity: 1;
  transition: 0.3s;
}

.splitter-layout > .layout-splitter:hover::after,
.splitter-layout > .layout-splitter:hover::before {
  top: calc(50% + 5px);
}
.splitter-layout > .layout-splitter:hover::before {
  top: calc(50% - 5px);
}
.splitter-layout-vertical > .layout-splitter:hover::after,
.splitter-layout-vertical > .layout-splitter:hover::before {
  top: auto;
  left: calc(50% + 5px);
}
.splitter-layout-vertical > .layout-splitter:hover::before {
  top: auto;
  left: calc(50% - 5px);
}

/**********************************************************/
/*                      Notifications                     */
/**********************************************************/
.notification_cmp .rnc__notification {
  width: auto !important;
  max-width: 600px;
}
.notification_cmp .rnc__notification-content::before {
  font-family: "Font Awesome Solid";
  font-weight: 900;
  position: absolute;
  left: 16px;
  top: 12px;
  font-size: 26px;
}
.notification_cmp .rnc__notification-item--success {
  background-color: rgb(237, 247, 237);
  border-left-color: rgb(30, 70, 32);
  color: rgb(30, 70, 32);
}
.notification_cmp .rnc__notification-item--success .rnc__notification-content::before {
  content: "\f058";
  color: #4caf50;
}
.notification_cmp .rnc__notification-item--success .notification__timer {
  background-color: #4caf50;
}
.notification_color_icon--success {
  color: #4caf50;
}
.notification_color_title--success {
  color: rgb(30, 70, 32);
}
.notification_cmp .rnc__notification-item--info {
  background-color: rgb(232, 244, 253);
  border-left-color: rgb(13, 60, 97);
  color: rgb(13, 60, 97);
}
.notification_cmp .rnc__notification-item--info .rnc__notification-content::before {
  content: "\f05a";
  color: #2196f3;
}
.notification_cmp .rnc__notification-item--info .notification__timer {
  background-color: #2196f3;
}
.notification_color_icon--info {
  color: #2196f3;
}
.notification_color_title--info {
  color: rgb(13, 60, 97);
}
.notification_cmp .rnc__notification-item--warning {
  background-color: rgb(255, 244, 229);
  border-left-color: rgb(102, 60, 0);
  color: rgb(102, 60, 0);
}
.notification_cmp .rnc__notification-item--warning .rnc__notification-content::before {
  content: "\f071";
  color: #ff9800;
}
.notification_cmp .rnc__notification-item--warning .notification__timer {
  background-color: #ff9800;
}
.notification_color_icon--warning {
  color: #ff9800
}
.notification_color_title--warning {
  color: rgb(102, 60, 0);
}
.notification_cmp .rnc__notification-item--danger {
  background-color: rgb(253, 236, 234);
  border-left-color: rgb(97, 26, 21);
  color: rgb(97, 26, 21);
}
.notification_cmp .rnc__notification-item--danger .rnc__notification-content::before {
  content: "\f06a";
  color: #f44336;
}
.notification_cmp .rnc__notification-item--danger .notification__timer {
  background-color: #f44336;
}
.notification_color_icon--danger {
  color: #f44336;
}
.notification_color_title--danger {
  color: rgb(97, 26, 21);
}
.notification_cmp .rnc__notification-item {
  padding-left: 46px;
}
.notification_cmp .rnc__notification-title {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: inherit;
}
.notification_cmp .rnc__notification-message{
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: inherit;
  max-width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

/**********************************************************/
/*                      Error message                     */
/**********************************************************/
.custom_error_msg_part_style {
  border: 1px solid rgba(97, 26, 21, 0.1);
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  font-size: 11px;
  font-style: italic;
  line-break: anywhere;
}
.custom_error_msg_part_style button{
  position: absolute;
  float: right;
  right: 20px;
}
.custom_error_msg_part_style span{
  text-decoration: underline;
}